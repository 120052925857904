.mainFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 767px) {
    .mainFooter {
        justify-content: left;
    }
    .f_link {
        display: none;
    }
}
@media (max-width: 550px) {
    .footer_r {
        display: flex;
    }

}