/*
.usersAvailable_Container {
    !*display: flex;*!
    !*flex-wrap: wrap;*!
    !*gap: 7px;*!
}
.UserNavMenu {
    !*margin-top: 5px;*!
    font-weight: bold;
}
.UserNavMenu ul {
    !*display: flex;*!
    !*gap:20px;*!
}
.usersAvailable_item  {
    display: flex;
   !* justify-content: center;*!
    border:1px solid black;
}
.userViewForm {
    !*width: 1040px;*!
    padding: 10px;
}
.usersAvailableTable img {
    width:20px;
}*/
.userViewForm {

    padding: 15px;
}