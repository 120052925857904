@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Ubuntu:wght@300;400;700&display=swap');

/* Default Color Skins */
:root {
  --charcoal-grey: #2f3337;
  --bluish: #2980b9;
  --tealish: #33cccc;
  --cloudy-blue: #b6c2cc;
  --white: #ffffff;
  --pale-grey: #eef4f7;
  --dark-grey-blue: #2c3e50;
}

/* Reset */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}
#page-wrap {
  display: flex;
  flex-direction: column;
}
/* Body */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #2f3337;
  margin: 0 auto;
  /*font-weight: 600;*/
  background: white;
  position: relative;
  min-height: 100%;
}

body.fixed {
  position: fixed;
  left: 0;
  top: 0;
}

/* Tags setting*/
/* Title */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  padding: 0;
  line-height: 1;
  color: #2f3337;
}

h1,
.h1 {
  font-size: 58px;
  font-weight: 300;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 25px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 20px;
}

/* Default tags */
p {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
  color: #2f3337;
}

a:hover {
  text-decoration: none;
  color: #33cccc;
  cursor: pointer;
}

a:focus {
  outline: none;
}
.create {
  color: #33cccc;
}
img {
  height: auto;
  vertical-align: middle;
  border: none;
  max-width: 100%;
}

main img {
  border-radius: 4px;
}

b,
strong {
  font-weight: 600;
}

form {
  padding: 0;
  margin: 0;
  display: block;
}

label {
  float: left;
  cursor: pointer;
}

input {
  border: 1px solid rgba(192, 192, 192, 0.33);
  margin-bottom: 23px;
  width: 100%;
  /*color: #CCCBCB;*/
  outline: none !important;
  background: white;
  padding: 14px 17px;
  font-size: 16px;
  border-radius: 12px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

textarea {
  /*text-transform: uppercase;*/
  margin-bottom: 25px;
  width: 100%;
  color: #656565;
  outline: none !important;
  min-height: 100px;
  border: 1px solid #cccbcb;
  background: transparent;
  padding: 15px 25px;
  font-size: 14px;
  display: block;
  border-radius: 4px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  background-color: #ecf0f1;
  font-size: 14px;
  color: #265878;
  padding: 7px 22px 7px 10px;
  width: 100%;
  outline: none !important;
  border: none;
}

.select,
.short {
  position: relative;
  display: inline-block;
}

.select:after,
.short:after {
  content: url('/src/assets/img/Path Copy.svg');
  pointer-events: none;
  position: absolute;
  right: -5px;
  bottom: -20px;
  rotate: 90deg;
  display: inline-block;
  font-size: 50px;
  font-weight: lighter;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #265878;
}

select:hover {
  cursor: pointer;
}

.select:hover:after,
.short:hover:after {
  color: #33cccc;
}

::-webkit-input-placeholder {
  color: #b6c2cc;
}

::-moz-placeholder {
  color: #b6c2cc;
}

:-moz-placeholder {
  color: #b6c2cc;
}

:-ms-input-placeholder {
  color: #b6c2cc;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ol {
  counter-reset: myCounter;
}

ol li {
  list-style: none;
}

ol li:before {
  counter-increment: myCounter;
  content: counter(myCounter);
  display: inline-block;
  margin: 0 10px 0 0;
  color: #2980b9;
}

address {
  font-style: normal;
}

blockquote {
  border-left: 1px solid #cccbcb;
  padding: 3px 0 7px 19px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 44px;
  color: #201e20;
  font-weight: 600;
  max-width: 600px;
}

main a img {
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
}

main a img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

article li {
  list-style: none;
  margin: 5px 0;
  position: relative;
  padding-left: 15px;
}

article ol li {
  padding-left: 0;
}

article ul li:before {
  content: '';
  /*position: relative;*/
  top: 42%;
  background: #2980b9;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  border-radius: 50%;
}

article ul,
article ol {
  margin: 15px;
}

main {
  /*  padding-top: 19px;*/
  /*  padding-bottom: 80px;*/
  flex: 1 1 66vh;
}

/* Custom Styles */
.container {
  max-width: 1140px;
  margin: 0 auto;
  width: 95%;
  /*position: relative;*/
}

.row:before,
.row:after {
  content: ' ';
  display: table;
  clear: both;
}

.row-15 {
  margin: 0 -15px;
}

.col {
  width: 100%;
}

.col-1-60 {
  width: 60%;
  float: left;
}

.col-1-40 {
  width: 40%;
  float: left;
}

.col-2 {
  width: 50%;
  float: left;
}

.col-3 {
  width: 33.33%;
  float: left;
}

.col-4 {
  width: 25%;
  float: left;
}

.col-5 {
  width: 20%;
  float: left;
}

.col-6 {
  width: 16.66%;
  float: left;
}

/* Other Styles */
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.center {
  text-align: center;
}

.more {
  display: inline-block;
  position: relative;
  padding-right: 15px;
}

.more::before {
  -webkit-transform: translateX(0) /*translateZ(0)*/;
  transform: translateX(0) /*translateZ(0)*/;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  content: '\f105';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 6px;
}

.more:hover::before {
  -webkit-transform: translateX(5px)
    /*translateZ(0/*);
    transform: translateX(5px) /*translateZ(0)*/;
}

iframe {
  width: 100%;
}

.show {
  display: block !important;
}

/* Header */
header {
  padding: 12px 0;
  position: relative;
  z-index: 99;
  object-fit: contain;
  box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: #ffffff;
}

/* Logo */
.logo {
  width: 10%;
  height: 100%;
}

.logo a {
  display: block;
}

.menu {
  font-family: 'Open Sans', sans-serif;
  float: left;
  /*width: 62%;*/
  padding: 47px 0 0 0;
  text-align: center;
}

.header_drop {
  float: left;
  width: 15%;
  padding-top: 42px;
}

.header_login {
  padding-top: 31px;
}
.no_login .login_drop span::before {
  display: none;
  border: 1px solid black;
}

.no_login .login_drop {
  padding-bottom: 20px;
}

.no_login .login_drop span {
  /* padding: 17px 11px 18px 33px;*/
  padding: 18px 13px 18px 35px;
}

.drop_l {
  float: left;
  width: 68px;
}

.drop_r {
  float: left;
  width: 68px;
  margin-left: 10px;
}

.drop {
  position: relative;
  color: #265878;
  font-size: 14px;
}

.drop:hover {
  cursor: pointer;
}

.drop span {
  display: block;
  padding: 5px 18px 6px 8px;
  border-radius: 6px;
  background-color: #ecf0f1;
  position: relative;
}

.drop span::before {
  content: '\f107';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  position: absolute;
  right: 11px;
  top: 8px;
}

.drop:hover span::before {
  color: #2980b9;
}

.drop ul {
  padding: 0;
  background-color: #ecf0f1;
  margin: 0;
  position: absolute;
  top: 25px;
  width: 100%;
  padding-top: 6px;
  left: 0;
}

.drop li {
  display: block;
}

.drop li a {
  color: #265878;
  display: block;
  padding: 3px 8px;
}

.drop li a:hover {
  text-decoration: none;
  /*background: --*/
  background-color: #2980b9;
  color: white;
}

/* Login */
.login_drop {
  position: relative;
  color: #265878;
  font-size: 14px;
  /*padding-bottom: 10px;*/
}

.login_drop:hover {
  cursor: pointer;
}

.login_drop:hover ul {
  display: block;
}

.login_drop span {
  display: block;
  padding: 11px 20px 11px 36px;
  position: relative;
  font-weight: 600;
  line-height: 1;
  color: #2f3337;
  border-radius: 8px;
  border: solid 1px #ecf0f1;
}

.login_drop span i {
  font-size: 17px;
  position: absolute;
  left: 11px;
  top: 15px;
  color: #33cccc;
}

.login_drop span::before {
  content: '\2193';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  position: absolute;
  right: 5px;
  color: #cad3da;
}

.login_drop:hover span::before {
  color: #2980b9;
}

.login_drop ul {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 38px;
  width: 100%;
  /* padding-top: 5px;*/ /* padding-bottom: 8px; */
  display: none;
  left: 0;
  border-radius: 8px;
  box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
  background-color: #ffffff;
}

.login_drop li {
  display: block;
}

.login_drop li a,
.login_drop li div {
  color: #2f3337;
  display: block;
  padding: 7px 10px;
}

.login_drop li a:hover,
.login_drop li div:hover {
  text-decoration: none;
  /*background: --;*/
  background-color: #2980b9;
  color: white;
}

/* Menu */
.menu nav ul {
  list-style: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.menu nav ul li {
  display: inline-block;
  position: relative;
  text-align: left;
}

.menu nav ul li a {
  font-size: 16px;
  font-weight: 550;
  color: #2c3e50;
  margin: 0 21px;
  display: block;
  position: relative;
  padding: 0 5px;
  /*text-decoration: none;*/
}

.menu nav ul li a:hover {
  color: #33cccc;
  text-decoration: none;
}

.menu nav ul li a.active {
  color: #33cccc;
  position: relative;
  display: inline-block;
}

.menu nav ul li a.active:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -7px;
  left: 0;
  background-color: #33cccc;
}

#menu_right_sidebar {
  display: none;
}

/* Mobile menu */
.container_right_menu {
  background: #ffffff;
  overflow-y: auto;
  padding: 40px 40px 30px 40px;
  /*position: fixed;*/
  /*top: 0;*/
  height: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  /*left: 0;*/
  /*width: 100%;*/
  /*  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);*/
}

.menu-right-button {
  padding: 5px 10px;
  cursor: pointer;
  line-height: 1;
  font-size: 28px;
  background: transparent;
  position: fixed;
  right: 20px;
}

.menu-right-button:hover,
.container_right_menu .close-button:hover {
  /*color: var(--theme_color_two);*/
}

.menu-right-button.fixed {
  display: block;
  position: fixed;
  top: 16px;
  right: 10px;
}

.menu-right-button.fixed:hover {
  background: #823bb9;
  color: white;
}

.container_right_menu .close-button {
  font-size: 40px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 20px;
  line-height: 0.7;
}

.container_right_menu.open {
  right: 0;
}

.container_right_menu .footer_item_social {
  display: flex;
  margin: 40px 0;
  text-align: center;
}

.container_right_menu .footer_item_social a:first-child {
  padding-left: 0;
}

.container_right_menu .footer_item_social a:last-child {
  padding-right: 0;
}

.container_right_menu .footer_item_social a {
  display: inline-block;
  line-height: 1;
  text-align: center;
  padding: 5px 13px;
  font-size: 20px;
  color: black;
}

.container_right_menu .footer_item_social a:hover {
  /* color: var(--theme_color_two);*/
}

.container_right_menu .footer_menu ul {
  width: 100%;
}

.container_right_menu .footer_menu ul li a {
  font-size: 16px;
  padding: 10px 0 10px 0;
  color: #201e20;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: left;
  text-decoration: none;
}

.container_right_menu .footer_menu ul li a:hover {
  /*color: var(--theme_color_two);*/
  text-decoration: none;
}

.container_right_menu .logotype_light {
  margin: 0 0 25px 0;
  display: block;
  text-align: center;
}

.container_right_menu .menu_contact li {
  list-style-type: none;
  color: #201e20;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  line-height: 1.3;
}

.container_right_menu .menu_contact li i {
  display: inline-block;
  margin-right: 10px;
  float: left;
}

.container_right_menu .dl-menuwrapper {
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
}

.container_right_menu .dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.container_right_menu .dl-menuwrapper li {
  position: relative;
}

.container_right_menu .dl-menuwrapper li a {
  display: block;
  position: relative;
  outline: none;
}

.container_right_menu .dl-menuwrapper li.dl-back:after,
.container_right_menu .dl-menuwrapper li > a:not(:only-child):after {
  position: absolute;
  top: 14px;
  right: 5px;
  speak: none;
  -webkit-font-smoothing: antialiased;
  font: normal normal normal 18px/1 FontAwesome;
  content: '\f105';
  font-size: 14px;
}

.container_right_menu .dl-menuwrapper li.dl-back:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.container_right_menu .dl-menuwrapper li > a:after {
  right: 10px;
  color: rgb(0, 0, 0);
}

.container_right_menu .dl-menuwrapper .dl-menu {
  opacity: 0;
}

.container_right_menu .dl-menuwrapper .dl-menu.dl-menu-toggle {
  transition: all 0.3s ease;
}

.container_right_menu .dl-menuwrapper .dl-menu.dl-menuopen {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.container_right_menu .dl-menuwrapper li .dl-submenu {
  display: none;
}

.container_right_menu .dl-menu.dl-subview li,
.container_right_menu .dl-menu.dl-subview li.dl-subviewopen > a,
.container_right_menu .dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.container_right_menu .dl-menu.dl-subview li.dl-subview,
.container_right_menu .dl-menu.dl-subview li.dl-subview .dl-submenu,
.container_right_menu .dl-menu.dl-subview li.dl-subviewopen,
.container_right_menu .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.container_right_menu .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

.container_right_menu .dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

.container_right_menu .dl-menu.dl-animate-out-3 {
  -webkit-animation: MenuAnimOut3 0.4s ease;
  animation: MenuAnimOut3 0.4s ease;
}

@-webkit-keyframes MenuAnimOut3 {
  100% {
    /*-webkit-transform: translateZ(300px);*/
    opacity: 0;
  }
}

@keyframes MenuAnimOut3 {
  100% {
    /*-webkit-transform: translateZ(300px);*/
    /*transform: translateZ(300px);*/
    opacity: 0;
  }
}

.container_right_menu .dl-menu.dl-animate-in-3 {
  -webkit-animation: MenuAnimIn3 0.4s ease;
  animation: MenuAnimIn3 0.4s ease;
}

@-webkit-keyframes MenuAnimIn3 {
  0% {
    /*-webkit-transform: translateZ(300px);*/
    opacity: 0;
  }
  100% {
    /*-webkit-transform: translateZ(0px);*/
    opacity: 1;
  }
}

@keyframes MenuAnimIn3 {
  0% {
    /*-webkit-transform: translateZ(300px);*/
    /* transform: translateZ(300px);*/
    opacity: 0;
  }
  100% {
    /* -webkit-transform: translateZ(0px);*/
    /* transform: translateZ(0px);*/
    opacity: 1;
  }
}

.container_right_menu .dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
  -webkit-animation: SubMenuAnimIn3 0.4s ease;
  animation: SubMenuAnimIn3 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn3 {
  0% {
    /* -webkit-transform: translateZ(-300px);*/
    opacity: 0;
  }
  100% {
    /*-webkit-transform: translateZ(0px);*/
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn3 {
  0% {
    /*-webkit-transform: translateZ(-300px);*/
    /* transform: translateZ(-300px);*/
    opacity: 0;
  }
  100% {
    /*  -webkit-transform: translateZ(0px);*/
    /*  transform: translateZ(0px);*/
    opacity: 1;
  }
}

.container_right_menu .dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
  -webkit-animation: SubMenuAnimOut3 0.4s ease;
  animation: SubMenuAnimOut3 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut3 {
  0% {
    /* -webkit-transform: translateZ(0px);*/
    opacity: 1;
  }
  100% {
    /*-webkit-transform: translateZ(-300px);*/
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut3 {
  0% {
    /*  -webkit-transform: translateZ(0px);*/
    /* transform: translateZ(0px);*/
    opacity: 1;
  }
  100% {
    /* -webkit-transform: translateZ(-300px);*/
    /* transform: translateZ(-300px);*/
    opacity: 0;
  }
}

/* Popup Styling */
button {
  transition: 0.3s ease;
}

button[type='submit'].loading {
  pointer-events: none;
  opacity: 0.4;
}

.white-popup {
  position: relative;
  background: #fff;
  padding: 50px 60px;
  width: 600px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
  animation: open 0.5s;
}

.white-popup .infobox {
  max-width: 100%;
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.5);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.5);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
  position: fixed;
  background: #ffffff;
  opacity: 1;
  animation: open 0.5s;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; /* animation: open 0.5s; */
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  animation: open 0.5s;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}

.mfp-zoom {
  /*cursor: pointer;*/
  /* cursor: -webkit-zoom-in;*/
  /*cursor: -moz-zoom-in;*/
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: block !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  min-width: auto;
}

.mfp-close:hover,
.mfp-close:focus {
  color: #33cccc !important;
  outline: none;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #2980b9;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #2980b9;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 1;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #2980b9;
}

.mfp-arrow-right:before {
  margin-right: 25px;
  border-left: 27px solid #2980b9;
  right: 8px;
  left: auto;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-right: 39px;
  left: auto;
  right: 0;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(140, 140, 140, 0.6);
  background: #fff;
  border-radius: 16px; /* box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1); */
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* Owl Nav Default*/
.owl-nav div,
.navigation div {
  height: 44px;
  text-align: center;
  width: 50px;
  float: left;
  position: relative;
}

.owl-nav div i,
.navigation div i {
  font-size: 41px;
  z-index: 2;
  position: relative;
}

.owl-nav div i:hover,
.navigation div i:hover {
  cursor: pointer;
  /* color: var(--theme_color_two);*/
}

.owl-nav .owl-prev,
.navigation .owl-prev {
  margin-right: 23px;
}

.owl-nav {
  position: absolute;
  bottom: 17%;
  left: 0;
  margin: 0 auto;
  max-width: 1140px;
  right: 0;
  z-index: 3;
}

.owl-next:hover i {
  color: white !important;
}

.owl-prev:hover i {
  color: white !important;
}

.owl-nav div i:before,
.navigation div i:before {
  z-index: 2;
  position: relative;
}

.owl-next:hover i:after,
.owl-prev:hover i:after {
  /*font-family: 'icomoon' !important;*/
  position: absolute;
  left: 0;
  top: 0;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 41px;
}

.owl-next:hover i:after {
  content: '\e91d';
}

.owl-prev:hover i:after {
  content: '\e91f';
}

.navigation .owl-next:hover i:after,
.navigation .owl-prev:hover i:after {
  color: white !important;
}

.navigation div i:hover {
  /* color: var(--theme_color) !important;*/
}

@media (max-width: 767px) {
  .white-popup button[type='submit'] {
    font-size: 16px;
    height: 48px;
  }
}

/* Tabs search */
.header_search input[type='radio'] {
  display: none;
}

/* .header_search label[for="toggle-tab1"] {margin-left: 23px;}
.header_search label{background: #ecf0f1;display:inline-block;margin-left: 7px;border-top-left-radius: 8px;padding: 7px 17px;cursor:pointer;transition:background .3s;-webkit-transition:background .3s;font-size: 16px;color: #2c3e50;border-top-right-radius: 8px;}
.header_search label:hover{background: #2980b9;color:#fff;} */
.header_search .tab {
  display: none;
}

#toggle-tab1:checked ~ label[for='toggle-tab1'],
#toggle-tab2:checked ~ label[for='toggle-tab2'],
#toggle-tab3:checked ~ label[for='toggle-tab3'],
#toggle-tab4:checked ~ label[for='toggle-tab4'],
#toggle-tab5:checked ~ label[for='toggle-tab5'],
#toggle-tab6:checked ~ label[for='toggle-tab6'] {
  background: #2980b9;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
  cursor: default;
}

#toggle-tab1:checked ~ #tab1,
#toggle-tab2:checked ~ #tab2,
#toggle-tab3:checked ~ #tab3,
#toggle-tab4:checked ~ #tab4,
#toggle-tab5:checked ~ #tab5,
#toggle-tab6:checked ~ #tab6 {
  display: block;
}

/* Back to top  */
#back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 2px 6px 0 /*var(--box_shadow_color)*/;
  display: none;
  font-size: 13px;
  border-radius: 4px;
  text-align: center;
  padding: 8px 10px;
  color: #fff;
  font-family: 'Ubuntu', sans-serif;
  z-index: 999999999999;
  background-image: linear-gradient(288deg, #33cccc, #2980b9);
}

#back-to-top i {
  margin-right: 5px;
  color: #fff;
}

#back-to-top:hover i {
}

#back-to-top:hover {
  background-image: linear-gradient(288deg, #2980b9, #33cccc);
  cursor: pointer;
}

.show_xs {
  display: none !important;
}

/* Header block */
.header_title {
  /*background-image: url('../src/assets/img/header_fon.jpg');*/
  min-height: 525px;
  padding: 53px 0 30px 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: scroll;
  background-size: cover;
}

.balloning .header_title {
  background-image: url('../src/assets/img/header-bg2.jpg');
}

.error200 .header_title {
  background-image: url('../src/assets/img/header-bg3.jpg');
}

.catalog .header_title {
  background-image: url('../src/assets/img/header-bg3.jpg');
}

.header_title h1 {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.31);
  color: #ffffff;
  line-height: normal;
  margin-bottom: 31px;
}

.header_title .container {
  max-width: 860px;
}

.input_search {
  max-height: 65px;
  font-size: 28px;
  padding: 15px 29px 16px 29px;
  float: left;
  width: 77.5%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #2980b9;
}
/*
.header_search button {
    float: right;
    width: 22.5%;
    max-height: 65px;
    min-width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: larger;
}*/

.section {
  padding: 0;
}

.services {
  padding-bottom: 20px;
}

.services_item {
  float: left;
  width: 25%;
  text-align: center;
  padding: 0 20px;
}

.services_item_vn {
  background: url('../src/assets/img/sfon.svg') no-repeat center top;
  font-size: 24px;
  font-weight: 300;
  color: #2f3337;
  padding-top: 12px;
  min-height: 190px;
}

.services span {
  display: block;
  line-height: 1.1;
  margin-bottom: 5px;
}

.services_num {
  font-family: Ubuntu, sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #2980b9;
}

.services_icon {
}

.services_item:nth-child(2) .services_item_vn {
  padding-top: 28px;
}

.services_item:nth-child(3) .services_item_vn {
  padding-top: 32px;
}

.row_title {
  margin-bottom: 39px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.row_title h3 {
  position: relative;
  padding: 21px 0;
  margin-bottom: 8px;
  display: block;
}

.row_title h3::before {
  content: '';
  position: absolute;
  top: 38px;
  left: 50%;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f6f8;
  z-index: -1;
}

.row_title p {
  margin-bottom: 0;
  margin: 0 auto;
  max-width: 555px;
  font-size: 14px;
}

.row_title_white h3,
.row_title_white p {
  color: white;
}

.row_title_white h3::before {
  background-color: #2980b9;
}

/* Carusel the best Block */
.owl-item {
  cursor: pointer;
}

.the_best {
  padding-bottom: 50px;
}

.the_best .owl-item a {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-image: linear-gradient(to bottom, rgba(44, 62, 80, 0), #1a2d3f);
}

.the_best .owl-item a span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

/* Carusel nav */
.owl-theme .owl-dots .owl-dot:focus {
  outline: none;
}

.owl-theme .owl-dots button.owl-dot span {
  border-radius: 4px;
  width: 30px;
  height: 6px;
}

.owl-theme .owl-dots button.owl-dot.active span {
  background: #33cccc;
}

.owl-theme .owl-dots button.owl-dot:hover span {
  background: #33cccc;
}

.owl-theme .owl-dots .owl-dot {
  min-width: auto;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot span {
  background: #ecf0f1;
}

.rectangle {
  background: url('../src/assets/img/rectangle.jpg') no-repeat;
  background-size: cover;
  padding: 55px 0 24px 0;
}

.rectangle .row_title p {
  margin-bottom: 49px;
}

.rectangle .container {
  max-width: 860px;
}

.home_text {
  padding: 65px 0 0 0;
}

.home_text .row_title {
}

.home_text_l {
  float: left;
  width: 50%;
  padding-right: 22px;
}

.home_text_r {
  float: right;
  width: 50%;
  padding-left: 22px;
}

.page_404 {
  padding-top: 50px;
}

.page_404_l {
  float: left;
  width: 50%;
  padding-left: 20px;
}

.page_404_r {
  float: left;
  width: 50%;
  padding-left: 18px;
  padding-top: 65px;
}

.page_404_r h1 {
  font-family: OpenSans, sans-serif;
  font-size: 58px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
  /*text-align: center;*/
  color: #2c3e50;
  text-align: left;
}

.page_404_r p {
  font-size: 18px;
  color: #2f3337;
  max-width: 390px;
}

.ht_content {
  margin-top: 68px;
}

.best_cities ul {
  display: flex;
  flex-wrap: wrap;
}

.best_cities li {
  display: block;
  margin: 0 0 12px 0;
  width: 25%;
  min-width: 140px;
}

.best_cities h3 {
  margin-bottom: 30px;
}

.best_cities {
  padding: 20px 0 0 0;
}

.the_top {
  padding-top: 11px;
  padding-bottom: 20px;
}

.the_top h3 {
  margin-bottom: 30px;
}

.the_top .owl-item a {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-image: linear-gradient(to bottom, rgba(44, 62, 80, 0), #1a2d3f);
}

.the_top .owl-item a span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 7px;
  font-size: 22px;
  font-weight: 600;
  /*text-align: center;*/
  color: #ffffff;
}

.blog_item {
  padding: 15px;
}

.row_item {
  /*display: none;*/
}

.blog_item .post_big,
.post_smoll_item a {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  max-width: 555px;
}

.post_smoll_item a {
  max-width: 263px;
}

.blog_item .post_big:before,
.post_smoll_item a:before {
  opacity: 0;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(26, 27, 27, 0.55);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.blog_item .post_big:hover:before,
.post_smoll_item a:hover:before {
  opacity: 1;
}

.blog_item .post_big:after,
.post_smoll_item a:after {
  opacity: 0;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: url('../src/assets/img/search-3.svg') no-repeat center center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.blog_item .post_big:hover:after,
.post_smoll_item a:hover:after {
  opacity: 1;
}

.post_smoll {
  margin-top: 15px;
}

.post_smoll_item {
  padding: 15px;
  float: left;
  width: 50%;
}

.post h3 {
  margin-bottom: 15px;
}

#loadMore {
  min-width: 295px;
  margin-top: 23px;
  display: none;
}

#loadMoredesc {
  min-width: 295px;
  margin-top: 23px;
}

.blog_item:nth-child(2n) .post_smoll {
  margin-top: -15px;
  margin-bottom: 15px;
}

/* faq review */
.faq {
  background: url('../src/assets/img/faqfon.jpg') no-repeat;
  background-size: cover;
  padding: 54px 0 37px 0;
  margin-top: 46px;
}

.faq h3 {
  margin: 0;
}

.faq .row_title {
  margin-bottom: 46px;
}

.faq_l {
  float: left;
  width: 50%;
  color: white;
  padding-right: 40px;
}

.faq_r {
  float: left;
  width: 50%;
  padding-left: 15px;
}

.faq_l ul li {
  display: block;
  position: relative;
  padding: 15px 0 15px 55px;
  font-family: Ubuntu, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  background: url('../src/assets/img/group.svg') no-repeat center left;
  font-weight: 300;
}

.review {
  color: #2f3337;
  font-family: Ubuntu, sans-serif;
}

.review_top {
  margin-bottom: 21px;
}

.review_top_l {
  float: left;
  width: 13%;
  padding-right: 14px;
}

.review_top_r {
  float: left;
  width: 80%;
  border-top-right-radius: 16px;
  max-width: 287px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #f0f6f8;
  padding: 20px 30px 22px 30px;
  line-height: 1;
}

.review_niz_r {
  float: left;
  width: 14%;
  padding-left: 14px;
}

.review_niz_l {
  float: left;
  width: 86%;
  line-height: 1.44;
  border-top-left-radius: 16px;
  max-width: 480px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #f0f6f8;
  padding: 20px 23px 25px 23px;
}

.faq_r .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 37px;
  padding-right: 36px;
}

.faq_r .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
}

/* error200 page */
.error200 .header_title {
  text-align: center;
  margin-bottom: 0;
  padding: 58px 0 10px 0;
  min-height: 307px;
}

.error200 .header_title h1 {
  margin-bottom: 11px;
}

.error200 .header_title p {
  margin: 0;
  color: white;
  margin-bottom: 30px;
}

.error200 .ht_content {
  margin-top: 48px;
}

.error200 .home_text {
  padding: 128px 0 22px 0;
}
.popularService {
  display: flex;
  max-width: 100%;
}
.popularService {
  max-width: 1000px;
}
.catalog_item {
  padding-top: 19px;
  margin-bottom: 74px;
}

.catalog_item h3 {
  margin-bottom: 17px;
}

.cat_item {
  font-family: 'Open Sans', sans-serif;
  float: left;
  padding: 12px 10px;
  width: 30%;
  height: 100%;
}

.cat_item_vn {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: #ffffff;
}

.cat_item_img {
  width: 263px;
  height: 196px;
  overflow: hidden;
  max-height: 197px;
  position: relative;
}

.cat_item_img a {
  height: inherit;
  width: 100%;
  position: relative;
  display: block;
}

.cat_item_img img {
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  transform-origin: center /*left*/;
}

.cat_item_img img:hover {
  -webkit-transform: scale(1.1) translate(-50%, -50%);
  -moz-transform: scale(1.1) translate(-50%, -50%);
  -o-transform: scale(1.1) translate(-50%, -50%);
}

.cat_item_title {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.cat_item_title_checkbox {
  flex: 0 1 5%;
}
.cat_item_title_title {
  flex: 0 1 95%;
}

.cat_item_title i {
  color: #2980b9;
  font-size: 26px;
  left: 0;
  top: 3px;
  position: absolute;
}

.cat_item_title i:hover,
.product_title i:hover,
.rating i:hover {
  cursor: pointer;
}

.cat_item_title a {
  font-size: 20px;
  font-weight: 400;
  /* padding-left: 30px;*/
  min-height: 60px;
  /*max-height: 60px;*/
  display: inline-block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cat_item_price {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.cat_item_desk {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
  color: #2f3337;
  min-height: 40px; /*add 05.07.2022*/
  max-height: 40px;
  overflow: hidden;
}

.cat_item_details {
  margin-top: 0;
}

.catalog_item .item_price {
  font-size: 18px;
  font-weight: bold;
  color: #33cccc;
  display: flex;
  flex-direction: column;
}

.catalog_item .item_price > span {
  font-size: 12px;
  font-weight: 400;
}

.rating i {
  color: #2980b9;
  float: left;
  margin-left: 7px;
  font-size: 16px;
}

.catalog_item .rating {
  margin-top: 5px;
}

.rating-container {
  display: flex;
  flex-direction: column;
}

.rating-container > .item_date {
  width: 100%;
  margin-left: 7px;
  margin-top: 3px;
}

.rating-container > .item_date i {
  display: inline-block;
  margin-right: 4px;
}

.rating .deactivate {
  color: #b6c2cc;
}

.cat_item_details a {
  background-color: #f0f6f8;
  display: block;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 600;
  text-align: center;
  color: #2980b9;
  transition: none;
}

.cat_item_details a:hover {
  color: white;
  background-image: linear-gradient(279deg, #33cccc, #2980b9);
}

.cat_item_center {
  padding: 14px;
}

.error200 .cat_item:nth-child(4n + 1) {
  clear: both;
}

.catalog .header_title {
  text-align: center;
  margin-bottom: 0;
  min-height: 307px;
}

.catalog .header_title h1 {
  margin-bottom: 31px;
}

.catalog .header_title p {
  /* margin: 0;*/
  font-family: Ubuntu, sans-serif;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  max-width: 585px;
  margin: 0 auto;
  color: white;
}

.catalog main {
  padding-top: 8px;
}

.catalog .cat_item:nth-child(3n + 1) {
  clear: both;
}

.catalog .cat_item {
  float: left;
  padding: 12px 15px;
  width: 33.3%;
}

/* breadcrumbs */
.breadcrumbs {
  margin: 8px 0 60px 0;
  font-size: 14px;
  color: #2f3337;
  line-height: 16px;
}
.breadcrumbs_product {
  margin: 15px 0 19px 0;
  font-size: 14px;
  color: #2f3337;
  line-height: 16px;
}
.breadcrumbs a,
.breadcrumbs_product a {
  display: inline-block;
  position: relative;
  padding-right: 20px;
}

.breadcrumbs a:after,
.breadcrumbs_product a:after {
  content: url('/src/assets/img/Path Copy.svg');
  position: absolute;
  right: 7px;
  top: 2px;
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumbs span {
  display: inline-block;
}

.catalog .catalog_item {
  padding-top: 0;
}

.sidebar {
  float: left;
  width: 25%;
  padding-right: 55px;
}

.catalog_r {
  width: 75%;
  padding-left: 7px;
  float: left;
}

.cat_top {
  margin-bottom: 3px;
}

.cat_top h4 {
  float: left;
  margin-bottom: 0;
  width: 83%;
}

.short {
  float: right;
  width: 17%;
  margin-top: -4px;
}

/* Pagination */
/*
.pagination  {
    padding: 24px 0 54px 0;
    margin-top: 28px;
    border-top: 1px solid #ecf0f1;
    text-align: center;
    font-size: 22px;

}
.pagination li {
    display: inline-block;
}
#pagination li a, #pagination li span {
    padding-top: 7px;
    display: inline-block;
    border: 1px solid #ECF0F1;
    margin: 0 4px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    text-align: center;
    color: #B6C2CC;
    background-image: none;
}
#pagination li a:hover {
    box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #ffffff;
    color: #2980b9;
    cursor: pointer;
    text-decoration: none;
    transition: 0.5s;
}
#pagination li a.active {
    box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #ffffff;
    color: #2980b9;
    cursor: pointer;
    text-decoration: none;
    transition: 0.5s;
}
#pagination li.disabled {
    pointer-events: none;
    opacity: 0.5;
}
#pagination .page-item. disabled button {
    border-color: #2ea3c0;
}
*/

/* Контейнер слайдера */
.wighet .price_slider input {
  border-radius: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2f3337;
  padding: 0;
  display: inline-block;
  margin: 0;
  float: left;
  text-align: center;
  border: none;
}

.wighet .price_slider {
  margin-top: 12px;
}

.wighet .ui-slider {
  position: relative;
}

.wighet .ui-slider .ui-slider-handle:focus {
  outline: none;
}

.wighet .ui-slider .ui-slider-handle:hover {
  background-image: linear-gradient(272deg, #33cccc, #2980b9);
}

.wighet .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: #ffffff;
  cursor: pointer;
}

.wighet .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  overflow: hidden;
}

.wighet .ui-slider-horizontal {
  height: 4px;
}

.wighet .ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -6px;
}

.wighet .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.wighet .ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.wighet .ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.wighet .ui-widget-content {
  background-color: #ecf0f1;
}

.wighet .ui-widget-header {
  background-image: linear-gradient(272deg, #33cccc, #2980b9);
}

.wighet .ui-corner-all {
  border-radius: 12px;
}

.wighet {
  margin-bottom: 55px;
}

.wighet_row {
}

.wighet h4 {
  margin-bottom: 18px;
}

.filter_links li {
  display: block;
  margin-bottom: 12px;
}
.filter_links .active {
  color: #33cccc;
}
.labeltext {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: #2f3337;
  display: inline-block;
  float: left;
  padding-top: 2px;
  padding-left: 10px;
}

.custom-big-checkbox input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.custom-big-checkbox {
  cursor: pointer;
  display: inline-block;
}

.custom-big-checkbox__checkbox {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  outline: solid 2px #b6c2cc;
  border: solid 2px white;
  transition: border 0.3s ease;
}

.custom-big-checkbox
  input[type='checkbox']:checked
  + .custom-big-checkbox__checkbox {
  background-color: #2980b9;
  outline: solid 2px #2980b9;
}

.custom-big-checkbox:hover .custom-big-checkbox__checkbox {
  background-color: #2980b9;
  outline: solid 2px #2980b9;
}

.checkbox_item {
  margin-bottom: 17px;
}

.checkbox_item:last-child {
  margin: 0;
}
.filter_links li {
  cursor: pointer;
}

/* Footer Block */
footer {
  background: url('../src/assets/img/footer_fon.jpg') no-repeat;
  background-size: cover;
  min-height: 158px;
  padding: 27px 0 40px 0;
  color: white;
  bottom: 0;
  width: 100%;
}
footer img {
}
footer a {
  color: white;
  font-size: 12px;
}

footer a:hover {
  color: #33cccc;
  text-decoration: none;
}

footer .footer_l {
  float: left;
  width: 15%;
}

footer .footer_r {
  float: left;
  width: 20%;
}

footer .footer_c {
  float: left;
  width: 25%;
  line-height: 1;
  padding-left: 9px;
}

footer .footer_logo {
  float: left;
  width: 80%;
  height: 100%;
}

footer .soc {
  float: left;
  width: 20%;
  padding-left: 24px;
  padding-top: 24px;
}

footer .soc span {
  font-size: 12px;
  display: block;
  color: #ecf0f1;
  line-height: 1;
  margin-bottom: 5px;
}

footer .soc .row a {
  display: inline-block;
  margin-right: 4px;
}

footer .soc .row a:hover svg,
.f_pay a:hover svg {
  filter: grayscale(100%);
}

footer .footer_r {
  line-height: 1;
}

footer .f_mail {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 24px;
}

footer .f_mail a {
  font-size: 24px;
  color: #ffffff;
}

footer .f_pay {
  float: left;
  width: 57%;
}

footer .f_pay a {
  display: inline-block;
  margin-right: 3px;
}

footer .f_pay a:last-child {
  margin: 0;
}

footer .f_link {
  float: right;
  width: 43%;
  padding-top: 15px;
}

footer .f_link a {
  text-decoration: underline;
}

footer .footer_r {
}

footer .footer_r ul {
  float: left;
  width: 50%;
  padding-left: 23px;
}

footer .footer_r ul li {
  list-style: none;
  display: block;
}

footer .footer_r ul li a {
  display: block;
  padding: 3px 0 7px 0;
}

/* account */
.account main {
  padding-top: 48px;
}

.account .wighet h5 {
  font-weight: 700;
}

.account .cat_top h4 {
  margin-bottom: 14px;
}

.bookings .cat_item_title a {
  padding: 0;
}

.account_links a {
  color: #b6c2cc;
  display: inline-block;
  padding-left: 17px;
  position: relative;
}

/*.account_links a:before {
    content: '';
    border: solid 2px #b6c2cc;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 7px;
    border-radius: 50%;
}*/

.account_links a:hover {
  color: #2f3337;
}

.account_links a:hover:before {
  border: solid 2px #33cccc;
}

.account_links li {
  display: block;
  margin-bottom: 12px;
}

.account_links .active {
  color: #2f3337;
}

.account_links .active:before {
  border: solid 2px #33cccc;
}

.item_date,
.item_user {
  float: left;
  width: 50%;
  font-size: 14px;
  padding-left: 0;
}

.item_date i,
.item_user i {
  font-size: 16px;
  color: #b6c2cc;
  margin-right: 2px;
}

.bookings .cat_item_price {
  margin-bottom: 11px;
}

.wishlist .cat_top h4 {
  margin-bottom: 2px;
}

.settings main {
  padding-top: 25px;
}

.settings .sidebar {
  padding-top: 25px;
}

.account_settings {
  padding: 21px 35px 40px 35px;
  border-radius: 12px;
  background-color: #ecf0f1;
  max-width: 750px;
}

.account_input span {
  display: block;
  margin-bottom: 6px;
}

.account_settings .col-2 {
  padding: 0 15px;
}

.account_input input {
  margin: 0;
}

.account_input {
  margin-bottom: 23px;
}

.account_button {
  padding: 0 15px;
}

.account_button button {
  width: 100%;
  margin-top: 28px;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  min-width: 100%;
}

.account_settings h4 {
  margin-bottom: 23px;
}

/* Product Page*/
/* Product slick Slider */
.slider-for.gallery,
.slider_nav_block {
  display: none !important;
}
.slider-for.gallery.slick-initialized,
.slider-for.gallery.slick-initialized + .slider_nav_block {
  display: block !important;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  /*cursor: pointer;*/
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  border-radius: 16px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  border-radius: 16px;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url('../src/assets/img/ajax-loader.gif') center center
    no-repeat;
}

.product main {
  padding-top: 21px;
}

.product .breadcrumbs {
  margin-bottom: 20px;
}

.product_title {
  position: relative;
  margin-bottom: 20px;
}

.product_title i {
  font-size: 35px;
  color: #b6c2cc;
  left: 0;
  top: 0;
  position: absolute;
}

.product_title h1 {
  margin-bottom: 0;
  padding-left: 40px;
  font-size: 58px;
}

.product_top {
  /*display: flex;*/
  /*flex-wrap: nowrap;*/
  margin-bottom: 40px;
}

.product_r_vn {
  border-radius: 16px;
  box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: #ffffff;
  position: relative;
  min-height: 468px;
}

.product_r_top {
  padding: 18px 30px;
}

.product_l {
  padding-right: 15px;
}

.product_r {
  padding-left: 15px;
}

.product .catalog_item {
  padding-top: 48px;
  margin-bottom: 23px;
}

.product .catalog_item h3 {
  margin-bottom: 28px;
}

.product .best_cities {
  margin-bottom: 30px;
}

.slider-for a {
  display: block;
  height: 360px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.slider-for a:before {
  content: '';
  position: absolute;
  right: 16px;
  top: 16px;
  width: 25px;
  height: 26px;
  background: url('../src/assets/img/lupa.svg') no-repeat center top;
  z-index: 9999;
  display: block;
}

.slider-for a:before:hover {
  opacity: 0.9;
}

.slider_nav_block {
  margin-top: 4px;
  position: relative;
  padding: 0 36px;
}

.slider_nav_block .angle_left {
  width: 42px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 14px;
  padding-top: 5px;
  height: 40px;
  background-color: #ecf0f1;
  font-size: 27px;
  color: #b6c2cc;
  line-height: 1;
  position: absolute;
  left: -39px;
  top: 27.5%;
  z-index: 1;
}

.slider_nav_block .angle_right {
  width: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 14px;
  text-align: right;
  padding-top: 5px;
  height: 40px;
  background-color: #ecf0f1;
  font-size: 27px;
  color: #b6c2cc;
  line-height: 1;
  position: absolute;
  right: -38px;
  top: 27.5%;
  z-index: 0;
}

.slider_nav_block .slider_nav_block_vn {
  margin: 0 4px;
  display: block;
  overflow: hidden;
  border-radius: 12px;
}

.slider_nav_block .slider_nav_block_vn img {
  border-radius: 12px;
  overflow: hidden;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
}

.slider_nav_block {
}

.slider_nav_block .angle_left:hover,
.slider_nav_block .angle_right:hover {
  color: #2980b9;
  cursor: pointer;
}

.slider_nav_block .slider_nav_block_vn:hover {
  cursor: pointer;
}

.slider_nav_block .slider_nav_block_vn:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.pick {
  font-size: 14px;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 16px 9px 30px 39px;
  background-color: #f0f6f8;
  width: 100%;
}

.pick_l {
  float: left;
  width: 80%;
  padding-right: 30px;
}

.pick_l strong {
  margin-bottom: 7px;
  display: block;
  font-family: Ubuntu, sans-serif;
  font-size: 24px;
  font-weight: normal;
}

.pick_r {
  float: left;
  width: 20%;
  padding-top: 13px;
}

.pick_r a {
  text-decoration: underline;
}

.pick_r a:hover {
  text-decoration: none;
}

.popup_content {
  text-align: center;
  margin: 10px;
}

.popup_content p {
  margin-bottom: 30px;
}

.popup_form input {
  box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
  background-color: #ffffff;
  padding: 22px 17px 20px 17px;
  margin-bottom: 30px;
}

.authorization {
  display: inline-block;
  height: auto;
}
.authorization span i {
  width: 50%;
  margin-right: 10px;
}
.popupContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.bg-popup {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}

.modal-Inner {
  position: relative;
  max-width: 95%;
}

.authorization_link {
  margin-top: 30px;
}

.authorization_link div {
  color: #33cccc;
  cursor: pointer;
}

.authorization_link div:hover {
  text-decoration: underline;
}

.popup_form button {
  margin-top: 10px;
}

.tabs .ui-state-active {
  background-color: #2980b9 !important;
}

.mfp-removing {
  animation: close 1s;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.product_title .fa-bookmark-o {
  color: #b6c2cc;
}

.fa-bookmark-o {
  color: #b6c2cc !important;
}

.product_r_top .rating {
  float: right;
  width: 25%;
  padding-top: 9px;
}

.time {
  float: left;
  width: 75%;
  font-size: 22px;
}

.time i {
  color: #2980b9;
  margin-right: 5px;
}

.time span {
}

.ui-selectmenu-button::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  position: absolute;
  left: 10px;
}

.options {
  margin: 30px 0 18px 0;
}

.options_item {
  margin-bottom: 8px;
  max-width: 160px;
}

.options_item .ui-selectmenu-button {
  background: transparent !important;
  border-radius: 8px;
  padding: 0;
  max-width: 160px;
}

.sel_icon {
  position: absolute;
  left: 17px;
  top: 9px;
  font-size: 22px;
  color: #b6c2cc;
}

.hasDatepicker {
  border-radius: 8px;
  max-width: 160px;
  border: solid 1px #ecf0f1;
  font-size: 14px;
  padding: 15px 17px 15px 52px;
  color: #2f3337;
  margin-bottom: 0;
}

.options_item {
  position: relative;
}
.options_item:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-bottom: 1px solid #b6c2cc;
  border-right: 1px solid #b6c2cc;
  transform: rotate(45deg);
  right: 10px;
  top: calc(50% - 5px / 2);
  pointer-events: none;
}
.ui-datepicker {
  padding: 10px;
  background: white;
  box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
}

.options ::-webkit-input-placeholder {
  color: #2f3337;
}

.options ::-moz-placeholder {
  color: #2f3337;
}

.options :-moz-placeholder {
  color: #2f3337;
}

.options :-ms-input-placeholder {
  color: #2f3337;
}

.ui-datepicker-header .ui-datepicker-prev:before {
  content: '\f104';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  position: absolute;
  top: 3px;
  color: #2980b9;
  left: 10px;
  color: #2980b9;
}

.ui-datepicker-header .ui-datepicker-next:before {
  content: '\f105';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  position: absolute;
  top: 3px;
  color: #2980b9;
  left: 12px;
  color: #2980b9;
}

.options_select {
  position: relative;
  max-width: 160px;
}

/* .options_select:after {position: absolute;right: 9px;top: 18px;display: inline-block;font: normal normal normal 15px/1 FontAwesome;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;content: "\f107";color: #ecf0f1;}  */
.options_select select {
  border-radius: 8px;
  max-width: 160px;
  border: solid 1px #ecf0f1;
  background: transparent;
  font-size: 14px;
  padding: 15px 17px 15px 52px;
  color: #2f3337;
}

.options_select .ui-selectmenu-button.ui-button {
  border-radius: 8px;
  max-width: 160px;
  border: solid 1px #ecf0f1;
  background: transparent;
  font-size: 14px;
  padding: 15px 17px 15px 52px;
  color: #2f3337;
}

.options_select .ui-selectmenu-icon.ui-icon {
  display: none !important;
}

.options_select .ui-selectmenu-button.ui-button:focus {
  outline: none;
}

.ui-selectmenu-menu .ui-widget.ui-widget-content {
  background: white;
  padding: 10px;
  border: solid 1px #ecf0f1;
  font-size: 14px;
  border-radius: 8px;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item {
  padding: 5px;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item:hover .ui-state-active {
  text-decoration: underline;
}

.price {
  font-size: 36px;
  color: #2f3337;
  margin-top: 20px;
}

.login_drop a {
  display: block;
}

.wighet:last-child {
  margin-bottom: 0;
}

.cancelComment {
  display: none;
}

.pp-credit-block-button {
  display: none;
}

.fill-secondary {
  fill: #2980b9;
}

.pp-credit-block-button {
  line-height: 1;
  font-size: 22px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1;
  color: #2f3337;
  border-radius: 8px;
  border: solid 1px #ecf0f1;
  padding: 6px 15px 6px 10px;
  margin: 0 auto;
  width: 105px;
  float: none;
}

/*** login */
.login_wrap > div {
  margin-bottom: 30px;
}

.login_wrap > div > input {
  margin-bottom: 0;
}

/*** login end*/
/*validation form */
.invlid-notice {
  color: rgb(236, 72, 105);
  font-size: 14px;
  display: block;
  text-align: left;
  margin-top: 5px;
}

.invalid-input {
  border-color: rgb(236, 72, 105);
}

/*validation form end*/

.articles-wrapper {
  padding: 0 150px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.article {
  margin-bottom: 60px;
}

.article:last-child {
  margin-bottom: 0;
}

.article__title {
  margin-bottom: 45px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.article__title:after {
  content: '';
  position: absolute;
  display: block;
  width: 77px;
  height: 77px;
  background: #f0f6f8;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.article__content {
  columns: 2;
  column-gap: 30px;
}

nav ul .drop-item .drop-menu {
  position: absolute;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  min-width: 105px;
  top: 22px;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-radius: 10px;
  border: 1px solid #fbfbfb;
  border-top: 0;
  padding: 4px 0;
  transition-duration: 0.3s;
}

nav .drop-item:hover .drop-menu {
  display: flex;
  flex-direction: column;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

nav .drop-menu:hover {
}

.drop-menu__item {
  padding: 4px 0;
}

.drop-menu__item > .drop-menu__link {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.header_search form > div {
  display: flex;
  border-radius: 16px;
  overflow: hidden;
}

.header_search_flex {
  display: flex;
  border: 1px solid rgba(192, 192, 192, 0.33);
  border-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  overflow: hidden;
}

.header_search_flex > div {
  max-height: 65px;
  font-size: 24px;
  padding: 10px 29px 10px 29px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2980b9;
  background-color: #fff;
}

.header_search label {
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  padding: 0 !important;
}

.header_search_flex input,
.header_search_flex select {
  margin-top: 1px;
  padding: 2px 0;
  /*padding-left: 0;*/
  font-size: 16px;
  background-color: transparent;
  border: none;
  /*color: #B6C2CC;*/
  /* -webkit-appearance: auto;*/
  /*-moz-appearance: auto;*/
  appearance: auto;
  margin-bottom: 0;
}

.header_search_flex > div:first-child {
  border-right: 1px solid #d0d0d0;
}

.header_search_flex .options_item,
.header_search_flex .options_item .ui-selectmenu-button {
  max-width: 1000px;
  font-size: 18px;
  width: 100%;
  color: #b6c2cc;
  margin-bottom: 0;
}

.header_search_flex .options_item:before {
  top: calc(50% + 23px / 2);
}

.ui-selectmenu-icon.ui-icon {
  display: none;
}

@media all and (max-width: 570px) {
  .header_search form > div {
    flex-direction: column;
    overflow: visible;
  }

  .header_search_flex {
    margin-bottom: 8px;
    flex-direction: column;
    border-radius: 12px !important;
  }

  .header_search_flex > div {
    padding: 10px;
    border: none !important;
  }
}

.my-account-non-auth {
  display: flex;
  justify-content: space-between;
}

.my-account-non-auth > div {
  max-width: 45%;
}

.my-account-non-auth > div .authorization_link {
  display: none;
}

@media (max-width: 800px) {
  .my-account-non-auth {
    flex-direction: column;
  }

  .my-account-non-auth > div {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

footer .white-popup {
  display: none !important;
}

.review-modal {
  position: fixed !important;
  left: 0;
  top: 0;
}

.review-modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.article-bottom {
  /*padding-top: 100px;*/
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-title__edit {
  color: #2980b9;
  font-weight: 700;
  cursor: pointer;
}

.edit-title__cancel {
  color: #c74d4d;
  font-weight: 700;
  cursor: pointer;
}

.account_input > span {
  font-weight: 700;
}

.account_settings_form form input {
  color: #2f3337;
  background-color: transparent;
  transition: 0.3s ease;
}

.account_settings_form form.form-edit-active input {
  /*color: 1px solid rgba(192, 192, 192, 0.63);*/
  background-color: #ffffff;
}

/** wish list **/
.catalog_wishlist .item_date {
  display: none;
}

.catalog_wishlist .rating-container {
  justify-content: flex-end;
}

.rating i:first-child {
  margin-left: 0;
}

.row {
  --bs-gutter-x: 0 !important;
}

.productAccordionArrow {
  color: #2db7f5;
}
.productAccordionArrow:focus {
  color: #6c6c6c;
}
.swiper-pagination {
  position: initial !important;
  padding-top: 10px;
}
.swiper-pagination span {
  border-radius: 4px;
  width: 30px;
  height: 6px;
}
.swiper-pagination span:active {
  background: #33cccc;
}
.swiper-pagination span:hover {
  background: #33cccc;
}
.swiper-pagination-bullet-active {
  background: #33cccc !important;
}
.rsw-toolbar button:focus {
  color: #33cccc;
}
.add-lable-block {
  display: flex;
  align-items: center;
}

.add-lable-text {
  margin-bottom: 0px;
}
/*
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    background-image: none;
    max-height: 65px;
 height: 65px;
 display: inline-flex;
 align-items: center;
 justify-content: center;
 min-width: 260px;
 border: none;
 padding: 20px 40px;
 text-shadow: 0 1px 1px rgb(0 0 0 / 34%);
 font-size: initial;
 font-weight: initial;
 line-height: initial;
 text-align: center;
 color: #ffffff;
 object-fit: contain;
 font-family: 'Open Sans', sans-serif;
 border-radius: 16px;

}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
background-image: none;
    background-color: rgb(0 0 0 / 0%) !important;
    text-decoration: initial;
    cursor: pointer;
    color: initial;
}
.css-169iwlq-MuiCalendarPicker-root button {
    background-image: none;
    max-height: initial;
    height: initial;
    display: initial;
    align-items: initial;
    justify-content: initial;
    min-width:initial;
    border: initial;
    padding: initial;
    text-shadow:initial;
    font-size: initial;
    font-weight: initial;
    line-height: initial;
    text-align: center;
    color: initial;
    object-fit:initial;
    font-family: initial;
    border-radius: initial;

}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    border-radius: 15px;
}*/
