.wrapper {
    background-color: #f4f6f9;
}

.formContainer {
display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
.createBlocks {
    display: flex;
    gap: 15px;
    padding: 10px;
    flex-wrap: wrap;
    box-shadow: 0 0 15px #9abfc7;
    border-radius: 15px;
}
.serviceCreateItem {
    flex:0 0 40%
}

.dragblImageWrap {
    display: flex;
    gap: 5px;
    width: 100%;
    overflow: hidden;
}

.dragblImageBlock {
    min-width: 200px;
    min-height: 150px;
}

.dragblImage {
    width: 100%;
    height: auto;
}

.navButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* background-color: #295673;
    color: white; */
    background-color: #aeccd4;
    border-radius: 8px;
    padding: 0px 5px;
    font-weight: 600;
}

.deleteButtonWrap {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.deleteButtonWrap div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 30px;
    /* background-color: rgb(255, 115, 115); */
    border: 1px solid #295673;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: transform 0.5s ease;
}

.deleteButtonWrap div:hover {
    background-color: #295673;
    color: white;
}

@media(max-width: 1180px) {
    .dragblImageBlock {
        min-width: 150px;
        min-height: 100px;
    }
}
@media(max-width: 900px) {
    .dragblImageBlock {
        min-width: 100px;
        min-height: 5dvh;
    }

    .deleteButtonWrap div {
        width: 60px;
        height: 25px;
        font-size: 14px;
    }
}