.mainAdminContainer {
}

.adminContainer {
  /* width:90%;*/
  /*background-color:#ecf0f1;*/
  display: flex;
  position: relative;
}
.adminContainer select {
  padding: 10px;
  background-color: white;
}
.adminMain {
  background-color: #aeccd4;
  min-height: 700px;
  height: 100%;
}
.menuColumnLeft {
  min-height: 100%;
  /*margin-right: 10px;*/
  background-color: #295673;
}

.menuBurgerColumnLeft {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #295673;
  color: white;
  height: 100%;
}

.menuBurgerLeftBlock {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 20px;
  width: 300px;
  background-color: #295673;
}

.menuBurgerColumnLeft_Item {
  margin-bottom: 15px;
}
.menuBurgerColumnLeft_Item a {
  color: white;
}

.menuColumnLeftIconWrap {
  width: 5%;
  display: none;
}

.menuColumnLeftIcon {
  display: block;
  width: 20px;
  height: 20px;
  margin: 10px 0px 0px 10px;
  color: black;
}

.closeMenuBurgerIcon {
  display: block;
  width: 20px;
  height: 20px;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
}

.contentColumnRight {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
}
.error {
  color: red;
}

.menuLeftNameItems,
.menuLeftIcons {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 20px;
  width: 300px;
}
.menuColumnLeft_Item {
  margin-bottom: 15px;
}
.menuColumnLeft_Item .active {
  color: white;
}
.createNewCategoryContainer form {
  padding: 10px;
}
.editImage img {
  width: 20px;
}
.menuColumnLeft_Item a {
  color: white;
  padding: 5px;
}
.menuColumnLeft_Item a:hover {
  background-color: #14435e;
  color: white;
}
.topPanel {
  display: flex;
  gap: 15px;
  align-items: center;
}
.topPanel .select {
  min-width: 24%;
  font-size: 0.89em;
}
.createButton span a {
  padding: 10px;
  margin-right: 10px;
  color: white;
  background-color: #295673;
  display: block;
  width: max-content;
}
.createButton span a:hover {
  color: #14435e;
}
.editDelImg {
  width: 30px;
  padding: 0 5px;
}
.createServiceLink {
  margin-left: 20px;
}
/*
.createButton {
    margin-left: 10px;
    display: inline-block;
    width: 150px;
    text-align: center;
   !* border-radius: 16px;
    box-shadow:3px 3px 3px #6c6c6c;*!
}*/
.createButton a {
  padding: 10px;
  margin-right: 10px;
  color: white;
  background-color: #295673;
}
.createButton a:hover {
  color: #14435e;
}
.formContainer {
  padding: 10px;
  background-color: #f2f4f8;
}
.wrapper {
  background-color: #f4f6f9;
}
.wrapper h4 {
  text-align: center;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.createBlocks {
  display: flex;
  gap: 15px;
  padding: 20px;
  margin: 10px;
  flex-wrap: wrap;
  box-shadow: 0 0 15px #9abfc7;
  border-radius: 15px;
}
.createComplete {
  position: relative;
  padding: 10px 0;
  top: 0;
  left: 0;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  background: rgba(205, 214, 219, 0.3);
}
.serviceCreateItem {
  flex: 0 0 40%;
}

.statusEl select {
  min-height: 45px;
}

.secondary {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 15px #9abfc7;
  margin: 15px 0 0 0;
}
.serviceCreateItemImg img {
  max-width: 100%;
  max-height: 100px;
  padding-left: 20px;
}
.serviceCreateItemImageSwiper {
  flex: 0 0 25%;
  text-align: center;
  margin-left: 0 !important;
}
.datePicker {
  max-width: 250px;
}
.datePicker button {
  height: 0;
  padding: 20px 15px;
}
.datePicker button:hover {
  background-image: initial;
}
.select {
  width: 200px;
}
.switchElement {
  display: flex;
  flex-direction: column;
}

.buttonTab {
  padding: 10px;
    margin-right: 10px;
    color: white;
    background-color: #295673;
    border: none;
    font-size: 16px;
}

.buttonActive {
  background-image: linear-gradient(288deg, #2980b9, #33cccc);
  color: white;
}

.inputActive {
  background-color: white;
}

.inputDisabled {
  background-color: transparent;
}

.buttonInactive {
  /* background-color: grey;
  color: black; */
}

@media(max-width: 1070px) {
  .menuColumnLeft {
    display: none;
  }
  .menuColumnLeftIconWrap {
    display: block;
  }
  .menuBurgerColumnLeft {
    display: block;
  }
}
