.wraperBtn {
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px;
}

.tableContainer {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-bottom: 20px;
}

.defaultEditor {
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  gap: 30px;
}
